/* Other rules */

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

.container {
  width: 60vw;
  margin: 20px auto;
  min-height: 60vh;
}

p {
  margin: 0;
}

.hide {
  display: none;
}

.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* General Typesetting .. Fonts ----------------------------------------------- */

/* Header and Nav bar */
.header {
  background-color: white;
  margin: 0;
  height: 60px;
  border-bottom: 1px solid lightgrey;
  position: sticky;
  top: 0;
}

.pan-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.nav {
  width: 80vw;
  height: 60px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* Search ... Filter ------------------------------------------ */
.filter {
  font-size: 12px;
  height: 40px;
  padding: 0px 15%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #f5f6f7;
}

.search-pan {
  flex-grow: 2;
}

.search-bar {
  font-size: 16px;
  height: 36px;
  width: 70%;
  padding-left: 20px;
  margin-left: 10%;
  background-color: #f5f6f7;
  border-radius: 5px;
  border: none;
}

.search-bar:focus {
  outline: none;
}

.sliders-pan {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sliders-pan > :first-child {
  min-width: 80px;
  align-self: center;
  margin-right: 15px;
}

.slider {
  width: 80%;
}

.Switch {
  color: #1976d2;
}

.pages > * {
  margin-left: 10px;
}

/* Sign .in ...  ---------------------------------------------------- */

.sign-in {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.user {
  color: #09aeb7;
  font-size: 12px;
}

.username {
  color: #6a040f;
}

/* Buttons ... misc --------------------------------------------- */
.btn-login,
.btn-sale {
  margin: 0px 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 26px;
  width: 160px;
  background-color: white;
  color: #09aeb7;
  border: 1px solid #09aeb7;
  font-size: 12px;
  border-radius: 5px;
}

.btn-sale {
  background-color: #09aeb7;
  color: white;
}

.btn-login:hover,
.btn-sale:hover {
  cursor: pointer;
}

.offers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 30px;
}

.card {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 500px;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #f8f9fa;
}

.card > :nth-child(n + 4) {
  margin-left: 8px;
  color: #6c757d;
}

.card > :nth-child(3) {
  margin-left: 8px;
  color: black;
}

.card > :first-child {
  margin: 8px;
  color: black;
}

.card > p {
  margin-bottom: 5px;
}

.card-image {
  margin-bottom: 10px;
  width: 240px;
  height: 380px;
  object-fit: cover;
}

.product-card {
  margin: 0px auto;
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 30px;
}

.product-info {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 30px;
  border-radius: 5px;
  gap: 20px;
}

.product-info > button {
  justify-content: center;
}

.product-info > ul {
  border-bottom: 1px solid lightgray;
  padding-bottom: 20px;
}

.product-info > :first-child {
  font-size: 16px;
  font-weight: bold;
}

.product-keys {
  display: flex;
  font-size: 13px;
  line-height: 19px;
}

.product-keys :first-child {
  color: #6c757d;
  min-width: 60%;
}

.product-keys span {
  flex: 1;
}

.product-keys-checkout {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.cart-total {
  font-weight: bold;
}

.product-image {
  height: 500px;
  width: 300px;
  object-fit: cover;
  border-radius: 5px;
}

.product,
.login-pan {
  padding-top: 50px;
  margin: 0px;
  min-width: 100vw;
  min-height: 100vh;
  background-color: #ebedee;
}

.login-pan {
  background-color: white;
}

a {
  text-decoration: none;
}

.footer {
  padding: 5px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #ebedee;
}

.foot-notes {
  font-size: 12px;
  line-height: 40px;
  color: #495057;
  text-decoration: none;
}

.foot-notes a {
  text-decoration: none;
}

.form-pan {
  min-height: 100vh;
}

.login-form {
  margin: 100px auto;
  max-width: 240px;
  margin: 100px auto;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#offer-form {
  margin: 100px auto;
  max-width: 400px;
  margin: 100px auto;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: space-between;
}

#offer-form > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#offer-form > div > :first-child {
  width: 35%;
  color: #6c757d;
}

#btn-publish {
  align-self: center;
  margin-top: 20px;
}

#offer-label {
  align-self: center;
  margin-bottom: 20px;
  color: #09aeb7;
  font-size: 18px;
  font-weight: bold;
}

.btn-checkout {
  align-self: center;
  margin-top: 20px;
}

.btn-pay {
  display: flex;
  justify-content: center;
}
/* .stripe-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
} */

.card-stripe {
  margin-bottom: 20px;
}

.input-txt {
  font-size: 12px;
  margin: 15px auto;
  min-height: 30px;
  width: 100%;
  padding-left: 20px;
  background-color: #f5f6f7;
  border-radius: 5px;
  border: none;
}

.input-txt:focus {
  outline: none;
}

.newsletter {
  font-size: 12px;
}

.newsletter > :nth-child(2) {
  margin: 5px;
  font-weight: 500;
}
.newsletter > :nth-child(3) {
  margin: 10px 25px;
}
